export default {
  GET(state, data) {
    state.warehouseWeekOrder = data
  },
  SET_WAREHOUSE_PAGE_TITLE(state, data) {
    state.warehousePageTitle = data
  },
  WAREHOUSE_ORDERS_LIST(state, data) {
    state.warehouseOrdersList = data
  },
}
