import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = () => 'warehouse'
const Actions = crudActions(endpoint)
const getByProducts = () => axios.get(`${endpoint()}?sorting=list`)

export default {
  ...Actions,
  getByProducts,
}
